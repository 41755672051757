@import "_variables.scss";

.additional-resources {
  &.mobile {
    padding: var(--chakra-space-6-4);

    .header {
      font-weight: var(--chakra-fontWeights-bold);
      font-size: large;
      padding-top: var(--chakra-space-6-4);
    }

    .resource-box {
      padding-top: var(--chakra-space-6-4);
    }
  }
}

.document-page {
  .doc-upper-text {
    text-transform: uppercase;
  }
  .grid {
    grid-template-columns: auto 25%;
    padding: var(--chakra-space-13);
    max-width:1580px;

    @media (max-width: $breakpoint-mobile-desktop) {
      grid-template-columns: none;
      padding: var(--chakra-space-6-4);
    }

    .doc-center-body {
      height: 100%;
      max-width: 1200px;
      display: grid;

      .chakra-divider {
        border: 1px solid $color-border;
      }
      .md + .doc-center-topic {
        margin-top: -2.4rem !important;
      }
      .doc-center-topic + .md .example:first-child {
        margin-top: 1rem !important;
      }
    }

    .on-this-page {
      .header {
        font-weight: var(--chakra-fontWeights-semibold);
        font-size: $font-size-med;
        line-height: var(--chakra-lineHeights-10);
        color: $color-default-font-color;
        padding: var(--chakra-space-3-2);
        font-family: $font-family-semi-bold;
      }
      nav > a {
        font-weight: var(--chakra-fontWeights-normal);
        font-size: $font-size-reg;
        line-height: var(--chakra-lineHeights-10);
        padding: var(--chakra-space-3-2);
      }
      nav {
        padding: var(--chakra-space-3) var(--chakra-space-3)
          var(--chakra-space-16) 0px;
        overflow-y: auto;
        height: 70vh;
        @media (max-width: $breakpoint-mobile-desktop) {
          height: auto;
        }

        .jump-link {
          text-decoration: none;
          cursor: pointer;
          font-weight: normal;
          background-color: transparent;
          &.active,
          &:hover {
            font-weight: bold;
            background-color: $color-sidebar-hover-bg;
          }
          @media (max-width: $breakpoint-mobile-desktop) {
            text-decoration: underline;
            &.active,
            &:hover {
              font-weight: normal;
              background-color: transparent;
            }
          }
        }
      }
    }
    .additional-resources {
      border-top: solid 1px $color-border;
      padding: var(--chakra-space-7) var(--chakra-space-3) var(--chakra-space-2)
        0px;
      gap: 10px;

      .header {
        font-weight: var(--chakra-fontWeights-semibold);
        font-size: $font-size-med;
        line-height: var(--chakra-lineHeights-10);
        color: $color-default-font-color;
        padding: var(--chakra-space-3-2);
        font-family: $font-family-semi-bold;
      }
      .chakra-stack {
        gap: var(--chakra-space-3);
      }
      .resource-box {
        padding: var(--chakra-space-3-2) 0 var(--chakra-space-3-2)
          var(--chakra-space-3-2);
        gap: var(--chakra-space-4);
      }
      a {
        font-weight: var(--chakra-fontWeights-bold);
        font-size: $font-size-reg;
        line-height: var(--chakra-space-7);
      }
    }
  }

  &.restricted {
    padding: var(--chakra-space-11) var(--chakra-space-12-8)
      var(--chakra-space-11) var(--chakra-space-12-8);
    .restricted-alert {
      font-size: $font-size-med;
      padding-bottom: var(--chakra-space-4-8);
    }
  }
}
.doc-detail-page {
  height: 100%;
  gap: 0;

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: var(--chakra-space-1);
    margin-left: 20% !important;
    gap: var(--chakra-space-12-8);
  }
}
